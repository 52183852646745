<template>
  <div>
    <ejs-dialog
      ref="createPriceUnitAddPopup"
      :header="`골프요금 단가추가`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onPriceUnitAddPopupClosed"
    >
      <div class="window golfPriceUnitAdd">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">골프요금 단가추가</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">적용일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group date">
                                    <div class="form">
                                      <input-date
                                        ref="priceUnitAddDatePicker"
                                        format="YYYY-MM-DD"
                                        v-model="addDate"
                                        :notClear="true"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    (<span
                                      :style="{
                                        color: getDayOfWeekCaptionColor(
                                          addDateObj
                                        ),
                                      }"
                                      >{{
                                        getDayOfWeekCaption(addDateObj)
                                      }}</span
                                    >)
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-icon-custom="true"
                  @click.native="onAddPriceUnit"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onPriceUnitAddPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

import {
  getDayOfWeekCaptionColor,
  getDayOfWeekCaption,
  getFormattedDate,
} from "@/utils/date";

import InputDate from "@/components/common/datetime/InputDate";

export default {
  name: "priceUnitAddPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputDate,
    ErpButton,
  },
  mounted() {
    this.addDate = getFormattedDate(new Date());
  },
  data() {
    return {
      addDate: null,
    };
  },
  computed: {
    addDateObj() {
      return new Date(this.addDate);
    },
  },
  methods: {
    getDayOfWeekCaptionColor,
    getDayOfWeekCaption,
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    onShowPriceUnitAdd() {
      this.$refs.createPriceUnitAddPopup.show();
    },
    onAddPriceUnit() {
      if (!this.addDate) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["적용일자"])
        );
      }

      this.$emit("add", this.addDate);
    },
    onPriceUnitAddPopupClosed() {
      this.$refs.createPriceUnitAddPopup.hide();
      this.$emit("popupClosed");
    },
  },
};
</script>
